import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { navigate } from '@reach/router'

const axios = require('axios')

const Cta = ({ title, subtitle }) => {
  return (
    <section className="bukazu bukazu-section-full">
      <div className="container-fluid">
        <div className="row bukazu-row">
          <div className="col-12 align-items-center text-center">
            <div className="bukazu-content">
              <h3 className="bukazu-h3 text-white mb-0">
                {title ? title : 'Het is tijd om meer boekingen te ontvangen.'}
              </h3>
              <h3 className="bukazu-h3 text-white">
                {subtitle ? subtitle : 'Klaar om te starten?'}
              </h3>
              <Formik
                initialValues={{ first_name: '', last_name: '', email: '' }}
                onSubmit={(values, actions) => {
                  setTimeout(() => {
                    // alert(JSON.stringify(values, null, 2))
                    actions.setSubmitting(false)
                    axios({
                      url: 'https://api.bukazu.com/graphql',
                      method: 'post',
                      data: {
                        query: `
                    mutation createUser($email: String!, $first_name: String, $last_name: String) {
                      createUser(is_trial: true, email: $email, first_name: $first_name, last_name: $last_name) {
                        id
                      }
                    }`,
                        variables: {
                          email: values.email,
                          first_name: values.first_name,
                          last_name: values.last_name,
                        },
                      },
                    }).then(result => {
                      if (result.data.errors) {
                        alert(
                          'Er is iets mis gegaan, probeer het later nog eens of neem contact met ons op'
                        )
                      } else {
                        navigate('/bedankt-voor-u-aanmelding')
                      }
                    })
                  }, 1000)
                }}
                render={({
                  values,
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="input-group bukazu-input-group mt-3">
                      <input
                        type="text"
                        placeholder="Voer hier je e-mailadres in"
                        name="email"
                        className="form-control bukazu-input input"
                        aria-required="true"
                        aria-invalid="false"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      <div className="input-group-append ml-1">
                        <button
                          className="bukazu-p-large btn btn-primary bukazu-btn text-white"
                          type="submit"
                        >
                          Meld je aan
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              ></Formik>
              <p className="bukazu-p-small text-white mt-2">
                Probeer 30 dagen gratis. De trial stopt automatisch.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

Cta.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
}

export default Cta
