import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import classNames from 'classnames'
import Layout from 'components/layout'
import Head from 'components/head'
// import Box from 'components/box';
import PageBlocks from 'components/pageBlocks'
import Cta from 'components/cta/cta'

import Tent from 'icons/tent.svg'

import 'style/bookingsystem.scss'

const Index = ({ data }) => {
  console.log({ data })
  const fields = JSON.parse(data.buroBorkPage.field)

  return (
    <Layout locale={fields.locale} className="main-container">
      <Head
        pageTitle={fields.seo_title ? fields.seo_title : fields.title}
        locale={fields.locale}
        pageDescription={fields.meta_description}
      />
      <div className="bukazu bukazu-section-half  right">
        <div className="container-fluid">
          <div className="row bukazu-row justify-content-center">
            <div className="col-12 col-md-6 d-flex justify-content-center px-4 align-items-center">
              <div className="bukazu-wrapper-content">
                <div className="bukazu-content text-center">
                  <h1 className="bukazu-h1-header">Boekingssysteem</h1>
                  <p className="bukazu-p">
                    BUKAZU: het meest complete boekingssysteem
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <hr className="horizontal__first"></hr>
        </div>
      </div>
      <div className="bukazu bukazu-section-half right">
        <div className="container">
          <div className="row bukazu-row justify-content-center">
            <div className="features">
              {data.allItems.edges.map(item => (
                <div
                  className="features-block text-center bukazu-p bukazu-p--secondary"
                  key={item.node.id}
                >
                  {item.node.title}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="bukazu bukazu-section right">
        <div className="container">
          <div className="row bukazu-row justify-content-center">
            <p className="col-12 col-md-6 bukazu-p py-4">
              Elke keer dat je vakantiehuis, camper of boot verhuurd wordt, ben
              je blij. Maar er komt ook een hele hoop werk achteraan: je
              Excel-bestand updaten waarin je de huurders en de data bijhoudt;
              mailtjes versturen naar de klant en de beheerder; zelf facturen
              maken en daarbij goed opletten dat je geen fouten maakt. En dan
              moet je ook nog de betalingen verwerken én in de gaten houden. Zo
              heb je je eigen handmatige boekingssysteem bedacht, maar het kost
              je verschrikkelijk veel tijd.
            </p>
          </div>
        </div>
      </div>
      <div className="bukazu bukazu-section-half background-full right">
        <div className="container">
          <div className="row bukazu-row justify-content-center">
            <h2 className="bukazu-h2">Is dit hoe verhuren bij jou werkt?</h2>
            <div className="col-12 col-md-7 bukazu-p bukazu-p--bold py-4 px-3">
              <ul>
                <li>
                  Je houdt je boekingen handmatig bij, wat veel werk is en
                  fouten oplevert.
                </li>
                <li>
                  Je vindt het lastig om het overzicht te bewaren in je
                  boekingen.
                </li>
                <li>
                  Als iemand geboekt heeft, moet je handmatig de
                  beschikbaarheidsdata op je site bijwerken.
                </li>
                <li>
                  Zodra er geboekt is, moet je met meerdere mensen contact
                  hebben, wat veel tijd kost.
                </li>
                <li>
                  Je hebt je vakantieverblijven op andere sites staan, waar je
                  hoge commissies betaalt tot wel 30%.
                </li>
                <li>
                  Of je hebt al een boekingssysteem, maar het werkt niet goed,
                  is duur en heeft geen goede support.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="bukazu bukazu-section-half right">
        <div className="container">
          <div className="row bukazu-row justify-content-center">
            <div className="col-12 col-md-8 py-4">
              <h2 className="bukazu-h2 py-4">
                Kies voor een boekingssysteem dat je tijd oplevert én geld
                bespaart
              </h2>
              <p className="bukazu-p">
                Wil je het nu eens écht goed aanpakken? BUKAZU biedt je
                structuur, overzicht en gemak. Met ons boekingssysteem is het
                hele proces van verhuren geautomatiseerd. Er staat een
                boekingsmodule op je website, je klant zoekt, boekt en betaalt,
                en vervolgens gaat voor jou de rest vanzelf. In de overzichten
                van BUKAZU zie je precies wat er nog moet gebeuren en wat de
                opbrengsten zijn. Verhuren was nog nooit zo makkelijk. En dat
                voor een vaste lage prijs per jaar.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Cta
        title="Wil je zelf het gemak van ons boekingssysteem ervaren?"
        subtitle="Ontdek de voordelen. "
      />
      <div className="bukazu bukazu-section-half right">
        <div className="container">
          <div className="row bukazu-row justify-content-center">
            <div className="col-12 col-md-8 py-4">
              <h2 className="bukazu-h2 py-4">Voor wie is BUKAZU geschikt?</h2>
              <p className="bukazu-p">
                Ons boekingssysteem is geschikt voor verhuurders die meerdere
                vakantieverblijven van anderen in hun aanbod hebben én voor
                eigenaren die zelf hun verblijf willen verhuren. Je kunt
                eigenlijk alles verhuren, zolang je het voor minimaal één nacht
                is. En of je nu één vakantiewoning hebt, een camping, of meer
                dan 50 campers, BUKAZU geeft je alles wat je nodig hebt.
              </p>
            </div>
          </div>
          <div className="amenities">
            <div className="amenities-block  text-center bukazu-p bukazu-p--secondary">
              <img
                className="bukazu-image my-2"
                src="/images/types/bnb.svg"
                alt="Zelf je Bed and breakfast verhuren"
                width="150"
                height="150"
              ></img>
              Bed and Breakfasts
            </div>
            <div className="amenities-block text-center bukazu-p bukazu-p--secondary">
              <img
                className="bukazu-image my-2"
                src="/images/types/boat.svg"
                alt="Zelf je boot verhuren"
                width="150"
                height="150"
              ></img>
              Boten
            </div>
            <div className="amenities-block text-center bukazu-p bukazu-p--secondary">
              <img
                className="bukazu-image my-2"
                src="/images/types/camper.svg"
                alt="Zelf je camper verhuren"
                width="150"
                height="150"
              ></img>
              Campers
            </div>
            <div className="amenities-block text-center bukazu-p bukazu-p--secondary">
              <img
                className="bukazu-image my-2"
                src="/images/types/house.svg"
                alt="Zelf je vakantiehuis verhuren"
                width="150"
                height="150"
              ></img>
              Vakantiehuizen
            </div>
            <div className="amenities-block text-center bukazu-p bukazu-p--secondary">
              <img
                className="bukazu-image my-2"
                src="/images/types/tent.svg"
                alt="Zelf je camping verhuren"
                width="150"
                height="150"
              ></img>
              Campings
            </div>
          </div>
        </div>
      </div>
      <div className="bukazu bukazu-section-half right">
        <div className="container">
          <div className="row bukazu-row justify-content-center">
            <div className="col-12 col-md-8 py-4">
              <h2 className="bukazu-h2 py-4 text-center">
                De voordelen van BUKAZU als boekingssysteem
              </h2>
            </div>
            <div className="col-12 col-md-6 d-flex left px-4 align-items-center">
              <div className="bukazu-content d-flex flex-column">
                <h3 className="bukazu-h3 text-sm-left">
                  Het hele proces geautomatiseerd
                </h3>
                <p className="bukazu-p bukazu-p-x-medium text-center text-sm-left py-2">
                  Met BUKAZU heb je in één klap het hele verhuurproces
                  geautomatiseerd. Zodra er een boeking binnenkomt, gaat de rest
                  vanzelf. Er worden mails verstuurd naar alle mensen die
                  betrokken zijn, zoals de huurder, de beheerder, de schoonmaker
                  en de eigenaar. Alle partijen hebben ook nog eens hun eigen
                  logins bij BUKAZU. Zo ziet de eigenaar wanneer de gasten
                  aankomen en vertrekken en de gast zelf heeft inzicht in de
                  boeking.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6 align-items-center">
              <div className="bukazu-wrapper-image">
                <img
                  src="/images/boekingsysteem/auto.svg"
                  className="bukazu-image"
                  alt="Het hele proces geautomatiseerd"
                  width="500px"
                  height="100%"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bukazu bukazu-section-half left">
        <div className="container">
          <div className="row bukazu-row">
            <div className="col-6 col-6 col-12 col-md-6 d-flex left px-4 align-items-center">
              <div className="bukazu-content-wrapper">
                <div className="bukazu-content d-flex flex-column">
                  <h3 className="bukazu-h3 text-sm-left">
                    Een overzichtelijk dashboard
                  </h3>
                  <p className="bukazu-p bukazu-p-x-medium text-center text-sm-left py-2">
                    BUKAZU is het overzichtelijkste boekingssysteem voor de
                    verhuur van vakantieverblijven. In het dashboard zie je al
                    je accommodaties en boekingen in één overzicht. Zo weet je
                    direct welke gasten binnenkort aankomen en vertrekken. Je
                    ziet ook welke boekingen er zijn voor de komende tien dagen.
                    Dus word je gebeld door een potentiële huurder die last
                    minute een accommodatie wil boeken? In je dashboard zie je
                    in één oogopslag wat er mogelijk is.
                  </p>
                  <p className="bukazu-p bukazu-p-x-medium text-center text-sm-left py-2">
                    Klik op een boeking en je ziet direct alle details, zoals
                    naam en adres, het aantal gasten en de automatische mails
                    die verstuurd zijn. Hier regel je alles wat met de boeking
                    te maken heeft: de betalingen, de eindafrekening en
                    terugbetaling van de borg
                  </p>
                </div>
              </div>
            </div>
            <div className="col-6 col-6 col-12 col-md-6 px-4 d-flex right px-4 align-items-center">
              <div className="bukazu-wrapper-image">
                <img
                  src="/images/boekingsysteem/dashboard.svg"
                  alt="Een overzichtelijk dashboard"
                  className="bukazu-image"
                  width="500px"
                  height="100%"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bukazu bukazu-section-half right">
        <div className="container">
          <div className="row bukazu-row justify-content-center">
            <div className="col-12 col-md-6 d-flex left px-4 align-items-center">
              <div className="bukazu-content-wrapper">
                <div className="bukazu-content d-flex flex-column">
                  <h3 className="bukazu-h3 text-sm-left">
                    Nooit meer zorgen over de financiën
                  </h3>
                  <p className="bukazu-p bukazu-p-x-medium text-center text-sm-left py-2">
                    Met ons boekingssyteem heb je altijd inzicht in de
                    financiën. Handmatig facturen maken en zelf alle betalingen
                    bijhouden is verleden tijd. BUKAZU regelt het allemaal en
                    laat precies zien welke betalingen er nog openstaan. Je
                    hoeft dus niet meer zelf in de gaten te houden of er op tijd
                    betaald wordt, want je klant én jijzelf krijgen automatisch
                    een seintje. Ook het verrekenen van de borg is met BUKAZU
                    heel eenvoudig.
                  </p>
                  <p className="bukazu-p bukazu-p-x-medium text-center text-sm-left py-2">
                    In de maandoverzichten zie je precies welke inkomsten je per
                    maand hebt ontvangen en welke betalingen je nog moet doen,
                    zoals borg terugbetalen aan de huurder. Als je verhuurt
                    namens verschillende eigenaren, zie je in het overzicht in
                    één oogopslag wat je moet betalen aan welke eigenaar. In de
                    jaaroverzichten zie je de inkomsten per camper, boot of
                    vakantiehuis. De maand- en jaaroverzichten zijn te
                    exporteren naar pdf of csv, zodat je ze ook door kunt sturen
                    naar je accountant.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-6 col-6 col-12 col-md-6 px-4 d-flex right px-4 align-items-center">
              <div className="bukazu-wrapper-image">
                <img
                  src="/images/boekingsysteem/charts.svg"
                  className="bukazu-image"
                  alt="Nooit meer zorgen over de financiën"
                  width="500px"
                  height="100%"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bukazu bukazu-section-half left">
        <div className="container">
          <div className="row bukazu-row">
            <div className="col-6 col-6 col-12 col-md-6 d-flex left px-4 align-items-center">
              <div className="bukazu-content-wrapper">
                <div className="bukazu-content d-flex flex-column">
                  <h3 className="bukazu-h3 text-sm-left">
                    Naadloze integratie in je eigen website
                  </h3>
                  <p className="bukazu-p bukazu-p-x-medium text-center text-sm-left py-2">
                    Een boekingskalender van derden heeft vaak zijn eigen stijl,
                    maar niet bij BUKAZU. Je bepaalt de lay-out helemaal zelf.
                    Kies de kleuren die passen bij je huisstijl, zodat het
                    systeem volledig opgaat in je website. Zo heeft je huurder
                    niet eens door dat je een extern boekingssysteem gebruikt.
                    Je kunt ook zelf kiezen welke mogelijkheden je wilt laten
                    zien op je website. Ga je voor alleen de kalender? Of heb je
                    meerdere accommodaties en wil je ook de zoekfunctie met
                    filter toevoegen? Je hebt volledige vrijheid.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-6 col-6 col-12 col-md-6 px-4 d-flex right px-4 align-items-center">
              <div className="bukazu-wrapper-image">
                <img
                  src="/images/boekingsysteem/kalender.png"
                  alt="Naadloze integratie in je eigen website"
                  className="bukazu-image"
                  width="700px"
                  height="100%"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bukazu bukazu-section-half right">
        <div className="container">
          <div className="row bukazu-row justify-content-center">
            <div className="col-12 col-md-6 d-flex left px-4 align-items-center">
              <div className="bukazu-content-wrapper">
                <div className="bukazu-content d-flex flex-column">
                  <h3 className="bukazu-h3 text-sm-left">
                    Gebruiksvriendelijk voor de huurder
                  </h3>
                  <p className="bukazu-p bukazu-p-x-medium text-center text-sm-left py-2">
                    Niks zo vervelend als dat mogelijke huurders afhaken omdat
                    het boekingssysteem op je website onhandig is. Met de
                    kalender van BUKAZU op je site zien bezoekers direct of je
                    vakantiehuis, kampeerplek of B&B beschikbaar is en voor
                    welke prijs. En als je de zoekfunctie met filter ook
                    toevoegt, kunnen ze eenvoudig zoeken in alle mogelijkheden.
                    De software werkt uitstekend op desktop, mobiel en tablet,
                    zodat je klanten altijd en overal kunnen zoeken en boeken.
                    Wil je dat ze bij het boeken gelijk online kunnen betalen?
                    Ga dan voor de extra betalingsmodule en kies zelf welke
                    betaalmethodes je wilt aanbieden.
                  </p>
                  <ul>
                    <li>Ideal</li>
                    <li>Creditcard</li>
                    <li>Sofort</li>
                    <li>PayPal</li>
                    <li>Bancontact</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-6 col-6 col-12 col-md-6 px-4 d-flex right px-4 align-items-center">
              <div className="bukazu-wrapper-image">
                <img
                  src="/images/boekingsysteem/payment_icons.svg"
                  className="bukazu-image"
                  width="700px"
                  height="100%"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bukazu bukazu-section-half left">
        <div className="container">
          <div className="row bukazu-row">
            <div className="col-6 col-6 col-12 col-md-6 d-flex left px-4 align-items-center">
              <div className="bukazu-content-wrapper">
                <div className="bukazu-content d-flex flex-column">
                  <h3 className="bukazu-h3 text-sm-left">
                    Complete controle over je boekingen
                  </h3>
                  <p className="bukazu-p bukazu-p-x-medium text-center text-sm-left py-2">
                    Wil je controle houden over wie je verwelkomt in je
                    vakantiehuis? Van tevoren weten aan wie je een camper of
                    boot meegeeft? Met BUKAZU is een boeking pas definitief
                    zodra jij dat wilt, zodat je na de boeking contact kunt
                    opnemen met de gasten. Zo krijg je alvast een indruk van wat
                    voor mensen het zijn. Gaat de boeking door? Je regelt het
                    met één druk op de knop.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-6 col-6 col-12 col-md-6 px-4 d-flex right px-4 align-items-center">
              <div className="bukazu-wrapper-image">
                <img
                  src="/images/boekingsysteem/conrole.svg"
                  className="bukazu-image"
                  alt="Complete controle over je boekingen"
                  width="500px"
                  height="100%"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bukazu bukazu-section-half right">
        <div className="container">
          <div className="row bukazu-row justify-content-center">
            <div className="col-12 col-md-6 d-flex left px-4 align-items-center">
              <div className="bukazu-content-wrapper">
                <div className="bukazu-content d-flex flex-column">
                  <h3 className="bukazu-h3 text-sm-left">
                    Koppeling met andere boekingssystemen
                  </h3>
                  <p className="bukazu-p bukazu-p-x-medium text-center text-sm-left py-2">
                    Staan je vakantieverblijven ook op andere boekingssites?
                    Geen probleem. Je verbindt BUKAZU eenvoudig met andere
                    boekingssystemen. De kalenders communiceren met elkaar,
                    zodat je nooit een dubbele boeking hebt. De kalender werkt
                    samen met de boekingssystemen van:
                  </p>
                  <ul>
                    <li>Airbnb</li>
                    <li>Booking.com</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-6 col-6 col-12 col-md-6 px-4 d-flex right px-4 align-items-center">
              <div className="bukazu-wrapper-image">
                <img
                  src="/images/boekingsysteem/connections.svg"
                  className="bukazu-image"
                  width="700px"
                  height="100%"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bukazu bukazu-section-half left">
        <div className="container">
          <div className="row bukazu-row">
            <div className="col-6 col-6 col-12 col-md-6 d-flex left px-4 align-items-center">
              <div className="bukazu-content-wrapper">
                <div className="bukazu-content d-flex flex-column">
                  <h3 className="bukazu-h3 text-sm-left">
                    Reisverzekeringen: voor de complete service
                  </h3>
                  <p className="bukazu-p bukazu-p-x-medium text-center text-sm-left py-2">
                    Maak je service compleet met reisverzekeringen. Je geeft de
                    huurder zekerheid, en ook als verhuurder of eigenaar weet je
                    zeker dat je geen inkomsten misloopt. BUKAZU is
                    tussenpersoon bij de Europeesche Verzekeringen. Je hoeft dus
                    zelf geen contracten af te sluiten en dat scheelt heel wat
                    gedoe. En voor elke verzekering die je verkoopt, krijg je
                    provisie; een mooie extra inkomstenbron.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-6 col-6 col-12 col-md-6 px-4 d-flex right px-4 align-items-center">
              <div className="bukazu-wrapper-image">
                <img
                  src="/images/boekingsysteem/terms.svg"
                  alt="Reisverzekeringen: voor de complete service"
                  className="bukazu-image"
                  width="500px"
                  height="100%"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Cta />
      <div className="bukazu-section bukazu-section-half ">
        <div className="container">
          <div className="row bukazu-row justify-content-center">
            <div className="col-6 col-12 col-md-8 px-4 px-4">
              <h2 className="bukazu-h2 text-center">
                We blijven onszelf constant verbeteren
              </h2>
              <p className="bukazu-p px-4 py-4">
                BUKAZU is het meest complete boekingssysteem en biedt je alle
                opties om zorgeloos te verhuren. We zijn constant in
                ontwikkeling en maken BUKAZU steeds beter, zodat jij het
                optimale gemak hebt. En heb je een goed idee voor een nieuwe
                functie? Laat het ons weten. Als wij het net zo’n goed idee
                vinden als jij, zetten we onze programmeurs aan het werk om het
                te integreren in onze software. Zo profiteer je optimaal van
                BUKAZU.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="bukazu-section bukazu-section-half ">
        <div className="container">
          <div className="row bukazu-row justify-content-center">
            <div className="col-6 col-12 col-md-8 px-4 px-4">
              <h2 className="bukazu-h2 text-center">
                Ervaar onze persoonlijke service en support
              </h2>
              <p className="bukazu-p px-4 py-4">
                BUKAZU is ontzettend eenvoudig in gebruik. Voor het inrichten
                moet je even de tijd nemen, maar het is gelukkig heel eenvoudig.
                Daarna gaat alles vanzelf en heb je altijd overzicht. En als je
                vragen hebt, kun je altijd bij ons terecht. Je hebt altijd twee
                vaste aanspreekpunten: iemand voor de techniek, en iemand voor
                algemene vragen over de werking van BUKAZU. En we doen niet aan
                lange wachttijden of keuzemenu’s; we zijn makkelijk bereikbaar
                en helpen je snel.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="bukazu-section">
        <div className="container">
          <div className="row bukazu-row justify-content-center">
            <div className="col-6 col-12 col-md-8 px-4 px-4">
              <blockquote className="bukazu-p bukazu-p--secondary">
                “Voor onze boekingen en administratie maken wij gebruik van het
                boekingssysteem van BUKAZU. Ik zou iedereen met één of meer
                vakantiehuizen dit systeem aanraden. Het is bijzonder
                gebruiksvriendelijk en makkelijk te begrijpen. Onze gasten
                ontvangen alle informatie (in het Duits, Engels of Nederlands)
                automatisch. Voor ons is dit erg prettig werken. Ook wanneer wij
                zelf vragen hebben wordt er snel en adequaat gereageerd.”
              </blockquote>
            </div>
          </div>
        </div>
      </div>
      {fields.page_rows.map((row, index) => {
        let classes = 'page_row'
        console.log({ row })
        if (row.options && row.options.classes) {
          classes = classNames(classes, Object.values(row.options.classes))
        }
        return (
          <div key={index} className={classes}>
            <PageBlocks blocks={row.page_blocks} />
          </div>
        )
      })}
      <Cta
        title="Probeer BUKAZU gratis uit en ervaar hoe het je leven makkelijker maakt"
        subtitle="Doe de gratis trial van 30 dagen"
      />
    </Layout>
  )
}

Index.propTypes = {
  data: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query BuroBorkBoekingssysteem {
    buroBorkPage(slug: { eq: "boekingssysteem" }) {
      id
      field
    }
    allItems(filter: { group: { eq: "Features" } }) {
      edges {
        node {
          id
          title
          field
        }
      }
    }
  }
`

export default Index
